import React from 'react';
import Layout from '../../components/Layout';

import HeroTitle from '../../components/HeroTitle';

export default () => (
  <Layout>
    <HeroTitle title="Success! 👌🏻" />
    <main>
      <div className="strip">
        <div className="row">
          <p>Your build info email has been sent.</p>
        </div>
      </div>
    </main>
  </Layout>
);
